import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";
// theme
import theme from "./../../theme";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "bold",
    textAlign: "center",
  },
  content: {
    textAlign: "center",
    padding: theme.spacing(2),
  },
}));

export default ({
  open,
  text,
  title = "",
  onAccept = () => {},
  acceptText = "",
}) => {
  const classes = useStyles(theme);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title" className={classes.title}>
        {title}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <DialogContentText>{text}</DialogContentText>
        <Button
          size="large"
          variant="contained"
          onClick={onAccept}
          color="primary"
        >
          {acceptText}
        </Button>
      </DialogContent>
    </Dialog>
  );
};
